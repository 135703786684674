import React, { useEffect, useState } from 'react';
import useTracking from 'components/Globals/Analytics';
import { useFormik } from 'formik';
import get from 'lodash/get';
import * as Yup from 'yup';
import { useTranslation } from 'src/i18n';
import { useRouter } from 'next/router';

import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import Button from 'components/uiLibrary/Button';
import LinkButton from 'components/uiLibrary/LinkButton';
import Typography from 'components/uiLibrary/Typography';
import TextField from 'components/uiLibrary/TextField';
import Checkbox from 'components/uiLibrary/CheckBox';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import { errorMessageConverter } from 'containers/Accounts/utils';
import { useLoginMutation, useResendOtpMutation } from 'containers/Accounts/queries';

import { setCookie, getCookie } from 'utils/cookie';
import { useDialogs } from 'utils/hooks/useDialogs';
import { getUserByEmail } from 'utils/API';
import { submitHubspotForm } from 'utils/formUtils';
import useRouteChangeHandler from 'utils/hooks/useRouteChangeHandler';
import { trackFormSubmit, trackLoggedIn } from 'utils/tracking';
import { nextPageQueryName } from 'utils/auth';
import useAppContext from 'utils/hooks/useAppContext';
import { useSetUserData } from 'utils/hooks/useAuthenticatedUser';

import { LOGIN_TYPE, OB_EMAIL_ID } from 'constants/cookieConstants';
import { TP } from 'constants/index';

import {
  GOOGLE_OLD_TRACKING_SERVICES,
  ACTION_CALLBACK_STATUS,
  ACTION_CALLBACK_STEPS,
  SUB_COMPONENTS,
} from 'components/Globals/Analytics/constants';
import classes from './EmailForm.module.scss';

const fields = {
  email: {
    id: 'email',
    label: `${TP}.m_EMAIL`,
    type: 'email',
  },
  password: {
    id: 'password',
    label: `${TP}.m_PASSWORD`,
    type: 'password',
  },
  keepSignedIn: {
    id: 'isTrustedDevice',
    label: `${TP}.KEEP_ME_SIGNED_IN`,
  },
};

const EmailForm = ({
  handleFormType,
  setCurrentEmail,
  currentEmail,
  allSteps = {},
  trackingData = {},
  isReturningUser = false,
  setLoginViaOtp,
  setAccountSharing,
  setUserId,
}) => {
  const prefilledEmail = getCookie(OB_EMAIL_ID);
  const { isLoggedIn } = useAppContext();
  const { isLoginDialog, setIsLoginDialog } = useDialogs();
  const initialFormValues = {
    [fields.password.id]: '',
    [fields.email.id]: currentEmail || prefilledEmail || '',
    [fields.keepSignedIn.id]: false,
  };
  const { t } = useTranslation(['NS_REGISTRATION_V4', 'NS_APP_GLOBALS']);
  const Router = useRouter();
  const track = useTracking();

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorLoginWithLink, setErrorLoginWithLink] = useState(null);

  const setUserData = useSetUserData();
  const loginMutation = useLoginMutation();

  const { mutate: sendOtpMutation, isLoading: isSendOtpLoading } = useResendOtpMutation({
    onError: error => {
      setErrorLoginWithLink(error.message);
      track.actionCallback({
        ...trackingData,
        meta: {
          step: ACTION_CALLBACK_STEPS.SEND_PASSCODE_TO_EMAIL,
          actionStatus: ACTION_CALLBACK_STATUS.FAILURE,
          isReturningUser,
          errorMessage: errorMessageConverter(error),
        },
      });
    },
    onSuccess: () => {
      track.actionCallback({
        ...trackingData,
        meta: {
          step: ACTION_CALLBACK_STEPS.SEND_PASSCODE_TO_EMAIL,
          actionStatus: ACTION_CALLBACK_STATUS.SUCCESS,
          isReturningUser,
        },
      });
      handleFormType(allSteps.VALIDATE);
    },
  });

  useEffect(() => {
    setIsLoading(isSendOtpLoading);
  }, [isSendOtpLoading]);

  useRouteChangeHandler(setIsLoading);

  const handleClickShowPassword = () => {
    track.click({ ...trackingData, subComponent: SUB_COMPONENTS.SHOW_PASSWORD_TOGGLE, meta: { isReturningUser } });
    setShowPassword(show => !show);
  };
  const submitDataToHbspt = (formValues, id, hasSalesProspactDomain) => {
    const portalId = process.env.HUBSPOT_REGISTER_KEY;
    const env = process.env.APP_ENV;
    const formId =
      env === 'production' ? 'f9ed8784-aa34-4a5e-97fd-d8b6a2594f36' : '5799d9b9-c7d3-4ea9-be8a-d07a1614aacf';
    const payload = {
      fields: [
        { name: 'email', value: formValues[fields.email.id] },
        { name: 'login_method', value: 'Email' },
        { name: 'user_id__database_', value: id },
        { name: 'login_page_url', value: Router.asPath },
        { name: 'sales_prospect_domain', value: hasSalesProspactDomain },
      ],
    };

    submitHubspotForm(portalId, formId, payload);
  };

  const loginRedirect = () => {
    setIsLoginDialog({ isOpen: false });
    setCookie(LOGIN_TYPE, 'email');

    const currentURL = window.location.href;
    const nextPageURL = Router?.query?.[nextPageQueryName];

    if (nextPageURL) {
      window.location.replace(nextPageURL);
    } else if (currentURL.includes('register') || currentURL.includes('login')) {
      window.location.replace('/');
    } else {
      window.location.reload();
    }
  };

  const handleLoginRedirect = userData => {
    const {
      salesProspectEmail,
      registrationId,
      firstName,
      lastName,
      individualProfile = {},
      isInternalUser = false,
      accountSharing,
      email,
    } = userData || {};

    if (accountSharing?.otpIsRequired && accountSharing?.lastLoginTime) {
      setAccountSharing({ ...accountSharing, email });
      handleLoginLink();
      track.actionCallback({
        ...trackingData,
        meta: {
          id: accountSharing?.messageType,
          step: ACTION_CALLBACK_STEPS.DEVICE_VALIDATION,
          actionStatus: ACTION_CALLBACK_STATUS.SUCCESS,
        },
      });
      return;
    }

    if (accountSharing?.otpIsRequired && !accountSharing?.lastLoginTime) {
      setAccountSharing(accountSharing);
      setUserId(userData?.id);
      handleLoginLink();
      track.actionCallback({
        ...trackingData,
        meta: {
          id: accountSharing?.messageType,
          step: ACTION_CALLBACK_STEPS.DEVICE_VALIDATION_NEW,
          actionStatus: ACTION_CALLBACK_STATUS.SUCCESS,
        },
      });
      return;
    }

    if (isInternalUser) {
      loginRedirect();
      return;
    }
    if (!registrationId && !firstName && !lastName) {
      handleFormType(allSteps.REGISTRATION_UPDATE);
      return;
    }

    if (registrationId && !firstName && !lastName) {
      handleFormType(allSteps.REGISTRATION_UPDATE);
      return;
    }

    if (
      !individualProfile?.id &&
      !salesProspectEmail?.isSalesProspectEmail &&
      !salesProspectEmail?.isLinkedToOrganization
    ) {
      handleFormType(allSteps.REGISTRATION_UPDATE);
      return;
    }

    // NOTE: Need to check with backend if profileaccess is pending then also individual profile is not coming
    if (
      (!individualProfile?.id && registrationId && firstName && lastName) ||
      (individualProfile?.id && !registrationId && firstName && lastName)
    ) {
      loginRedirect();
      return;
    }

    if (salesProspectEmail?.isSalesProspectEmail) {
      if (salesProspectEmail.isLinkedToOrganization && individualProfile?.professions?.length > 0) {
        loginRedirect();
      } else {
        handleFormType(allSteps.REGISTRATION_UPDATE);
        return;
      }
    }
    loginRedirect();
  };

  const handleAuthError = (err, setFieldError) => {
    if (err.responseText === 'Password is incorrect') {
      setFieldError(fields.password.id, t(`NS_REGISTRATION_V4:${TP}.LOGIN_INVALID_PASSWORD`));
    } else if (err.responseText === 'User not found') {
      setFieldError(fields.email.id, t(`${TP}.LOGIN_INVALID_EMAIL`));
    } else if (err?.responseText?.includes('Password is not set')) {
      setFieldError(fields.password.id, t(`${TP}.LOGIN_PASSWORD_NOT_SET`));
    } else {
      setFieldError(fields.password.id, err.responseText);
    }
  };
  const formik = useFormik({
    initialValues: initialFormValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      [fields.password.id]: Yup.string('').required(t(`${TP}.m_LOGIN_REQUIRED`)),
      [fields.email.id]: Yup.string()
        .email(t(`${TP}.m_MAILNONVALID`))
        .required(t(`${TP}.m_LOGIN_REQUIRED`)),
    }),
    onSubmit: (values, { setSubmitting, setFieldError }) => {
      track.click({ ...trackingData, subComponent: SUB_COMPONENTS.LOGIN_BUTTON, meta: { isReturningUser } });
      setIsLoading(true);
      const payload = {
        email: values[fields.email.id],
        password: values[fields.password.id],
        isTrustedDevice: values[fields.keepSignedIn.id],
        popupSource: isLoginDialog?.source,
      };

      loginMutation.mutate(payload, {
        onSuccess: response => {
          const userData = response.data;
          if (!userData.accountSharing?.otpIsRequired) {
            setUserData(response.data);
            const hasSalesProspactDomain = response?.data?.salesProspectEmail?.isSalesProspectEmail;
            submitDataToHbspt(values, get(response.data, 'id'), hasSalesProspactDomain);
            delete payload.password;
            track.actionCallback({
              ...trackingData,
              meta: {
                step: ACTION_CALLBACK_STEPS.LOGIN_WITH_EMAIL,
                actionStatus: ACTION_CALLBACK_STATUS.SUCCESS,
                email: payload?.email,
                popupSource: payload?.popupSource,
                isReturningUser,
              },
            });
            track.click(trackLoggedIn({ email: values[fields.email.id] }), GOOGLE_OLD_TRACKING_SERVICES);
            track.click(trackFormSubmit({ email: values[fields.email.id] }), GOOGLE_OLD_TRACKING_SERVICES);
          }
        },
        onError: error => {
          setIsLoading(false);
          delete payload.password;
          track.actionCallback({
            ...trackingData,
            meta: {
              step: 'loginWithEmail',
              actionStatus: ACTION_CALLBACK_STATUS.FAILURE,
              errorMessage: errorMessageConverter(error),
            },
          });
          handleAuthError(error, setFieldError);
        },
        onSettled: (res, error) => {
          if (!error) {
            handleLoginRedirect(res.data);
          }
        },
      });

      setCookie(LOGIN_TYPE, 'email');
      setSubmitting(false);
    },
  });
  const errorMessage = !['User not found', 'Password is incorrect'].includes(formik.error) && formik.error;

  const handleLoginLink = async () => {
    track.click({ ...trackingData, subComponent: SUB_COMPONENTS.SECURITY_CODE_LINK, meta: { isReturningUser } });
    try {
      setIsLoading(true);

      const response = await getUserByEmail(formik.values.email);

      if (!response?.data?.email) {
        track.actionCallback({
          ...trackingData,
          meta: {
            step: ACTION_CALLBACK_STEPS.SEND_PASSCODE_TO_EMAIL,
            actionStatus: ACTION_CALLBACK_STATUS.FAILURE,
            payload: { email: formik.values.email },
            errorMessage: 'Invalid Email',
          },
        });
        setErrorLoginWithLink(t(`${TP}.LOGIN_INVALID_EMAIL`));
      } else {
        sendOtpMutation({ email: formik.values.email, otpType: 'EMAIL_LOGIN', popupSource: isLoginDialog?.source });
        setCurrentEmail(formik.values.email);
        setLoginViaOtp(true);
      }
    } catch (error) {
      setErrorLoginWithLink(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleError = errMessage => {
    if (!errMessage.includes('[400]')) {
      return errMessage;
    }
    return null;
  };

  const handleChange = e => {
    formik.handleChange(e);
    if (errorLoginWithLink) {
      setErrorLoginWithLink('');
    }
  };

  return (
    <div className={classes.emailForm}>
      <div className={classes.emailForm__header}>
        <Typography size="18" weight="medium" className={classes.emailForm__header_title}>
          {t(`${TP}.FN_LOGIN_WITH_PASSWORD_TITLE`)}
        </Typography>
      </div>
      <form onSubmit={formik.handleSubmit} className={classes.form}>
        <div className={classes.inputContainer}>
          <TextField
            id={fields.email.id}
            label={
              <span>
                {t(fields.email.label)} <span className={classes.form_required}>*</span>
              </span>
            }
            name={fields.email.id}
            type={fields.email.type}
            onChange={handleChange}
            {...formik.getFieldProps(fields.email.id)}
            InputLabelProps={{
              shrink: true,
            }}
            onClick={() => {
              track.click({ ...trackingData, subComponent: SUB_COMPONENTS.EMAIL_INPUT, meta: { isReturningUser } });
            }}
            value={formik.values.email}
            variant="outlined"
            className={classes.input}
            error={(formik.errors.email && Boolean(formik.errors.email)) || errorLoginWithLink}
            helperText={(formik.touched.email && formik.errors.email) || errorLoginWithLink}
            disabled={fields.email.id}
          />
        </div>
        <div className={classes.passCodeButtonContainer}>
          <Button
            className={classes.passCodeButtonContainer_button}
            variant="secondary"
            shape="rectangle"
            onClick={() => handleLoginLink()}
            tabIndex={-1}
          >
            {t(`${TP}.FN_SECURITY_CODE_LINK`)}
          </Button>
          <Typography variant="div" size="12" weight="bold" className={classes.orSeparator}>
            {t(`${TP}.FN_BOOLEAN_OPERATOR_OR`)}
          </Typography>
        </div>
        <div className={classes.inputContainer}>
          <TextField
            id={fields.password.id}
            label={
              <span>
                {t(fields.password.label)} <span className={classes.form_required}>*</span>
              </span>
            }
            name={fields.password.id}
            type={showPassword ? 'text' : fields.password.type}
            onChange={formik.handleChange}
            {...formik.getFieldProps(fields.password.id)}
            value={formik.values.password}
            variant="outlined"
            className={classes.input}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            InputLabelProps={{
              shrink: true,
            }}
            onClick={() => {
              track.click({ ...trackingData, subComponent: SUB_COMPONENTS.PASSWORD_INPUT, meta: { isReturningUser } });
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={handleClickShowPassword}
                  className={classes.inputContainer_passwordIcon}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </InputAdornment>
              ),
            }}
          />
          <div className={classes.inputContainer__bottomWrapper}>
            <div className={classes.inputContainer__checkboxWrap}>
              <Checkbox
                className={classes.inputContainer__checkboxWrap_checkbox}
                checked={formik.values.isTrustedDevice}
                id={fields.keepSignedIn.id}
                onChange={handleChange}
              />
              <Typography variant="label" htmlFor={fields.keepSignedIn.id} className={classes.inputContainer__label}>
                {t(fields.keepSignedIn.label)}
              </Typography>
            </div>
            <LinkButton
              variant="text"
              onClick={() => {
                handleFormType(allSteps.FORGOT_PASSWORD);
                setCurrentEmail(formik.values.email);
                track.click({
                  ...trackingData,
                  subComponent: SUB_COMPONENTS.FORGOT_PASSWORD_LINK,
                  meta: { isReturningUser },
                });
              }}
            >
              {t(`${TP}.m_LOGIN_FORGOT_PASSWORD`)}
            </LinkButton>
          </div>
        </div>
        {!isLoggedIn && <div className={classes.signInActions}></div>}
        <div className={classes.buttonContainer}>
          <Button
            type="submit"
            shape="rectangle"
            isLoading={isLoading}
            rightIcon={<SpriteIcon icon="chevron_right" size={16} />}
          >
            {t(`${TP}.m_LOGIN`)}
          </Button>
          <div className={classes.formFooter}>
            {errorMessage && (
              <Typography size={12} className={classes.errorMessage}>
                {handleError(errorMessage)}
              </Typography>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default EmailForm;
